.background {
	position: absolute;
	top: 0;
	left: 0;
	height: 100vh;
	width: 100vw;
	background-color: var(--backdrop);
	color: white;
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 50;
}

.wrapper {
	position: absolute;
	top: 5%;
	left: 5%;
	height: 90%;
	width: 90%;
	background-color: var(--bgLight);
	border: 1px solid var(--higlight);
	border-radius: 10px;
	box-shadow: 2px 2px 2px var(--bgColor);
	padding: 1rem;
	z-index: 60;
	overflow: auto;
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 1rem;
	overflow: auto;
}

.siteCover {
	width: 25%;
	height: 100%;
}

.formWrapper {
	display: flex;
	flex-wrap: wrap;
	gap: 2rem;
	justify-content: center;
}

@media (max-width: 768px) {
	.buttons {
		display: grid;
		justify-content: center;
	}
}
